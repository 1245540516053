import { render, staticRenderFns } from "./modalDetalleVisitante.vue?vue&type=template&id=b3d709e2&scoped=true&"
import script from "./modalDetalleVisitante.vue?vue&type=script&lang=js&"
export * from "./modalDetalleVisitante.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3d709e2",
  null
  
)

export default component.exports